.icon,
svg.icon{
	--width:var(--ui-icon-width, 32px);
	--height:var(--width);
	--backgound-color:transparent;
	display: inline-flex;
	width: var(--width);
	height: var(--height);
	border: none;
	padding: 0;
	background: none;
	backgound-color: var(--backgound-color);
}
