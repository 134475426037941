@mixin size($breakpoint) {
	@if map-has-key($screens, $breakpoint) {
		@media screen and (max-width: #{map-get($screens, $breakpoint)}) {
			@content;
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}

@mixin screen($size) {
	@media screen and (max-width: #{$size}px) {
		@content;
	}
}


@function em($pixels, $context: map-get($font, 'fontRatio')) {
	@return #{$pixels/$context}em;
}