.noselect{  
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.nowrap{
	white-space: nowrap;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    overflow: hidden;
}



.clamp{
    --clamp:2;
    display: -webkit-box;
    -webkit-line-clamp: var(--clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
	text-overflow: ellipsis;

	&.clamp-1{ --clamp:1; }
	&.clamp-2{ --clamp:2; }
	&.clamp-3{ --clamp:3; }
	&.clamp-4{ --clamp:4; }

}


.spinner{
	
	--size:2px;
	--speed:0.8s;
	--width:20px;
	--fill:var(--color);
	--background-color:transparent;

	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: var(--width);
		height:  var(--width);
		margin-top:  calc( var(--width) - calc( var(--width) * 1.5));
		margin-left: calc( var(--width) - calc( var(--width) * 1.5));
		border-radius: 50%;
		border: var(--size) solid var(--fill);
		border-top-color: var(--background-color);
		animation: spinner var(--speed) linear infinite;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
	}
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.cursor{
	&-close{
		--width:1em;
		--volume:1px;
		--color:#000;
		box-sizing: border-box;
		display: inline-block;
		width: var(--width);
		height: var(--width);
		position: relative;

		&:before,
		&:after {
			position: absolute;
			top: 0;
			left:50%;
			content: '';
			height: var(--width);
			width: 0;
			border: solid var(--color);
			border-width: 0px var(--volume) 0 0;
			background-color: var(--color);

		}
		&:before {
				transform: rotate(45deg);
		}
		&:after {
				transform:rotate(-45deg);
		}
	}
	&-up, &-right, &-down, &-left{
		--width:1em;
		--volume:2px;
		--color:#000;
		box-sizing: border-box;
		display: inline-block;
		width: var(--width);
		height: var(--width);
		position: relative;

		&:before,
		&:after {
			position: absolute;
			top: 0;
			left:50%;
			content: '';
			height: var(--width);
			width: 0;
			border: solid var(--color);
			border-width: 0px var(--volume) 0 0;
			background-color: var(--color);

		}

	}
	&-up{
		&:before {
			transform: rotate(0deg);
		}
		&:after {
			transform:rotate(-90deg);
		}
	}

}


.right, .left,.up,.down {
	--width:1em;
	--volume:2px;
	--color:#000;
	border: solid black;
	border-width: 0 var(--volume) var(--volume) 0;
	box-sizing: border-box;
	display: inline-block;
	width: var(--width);
	height: var(--width);
	position: relative;

	&.up {
		transform: rotate(-135deg);
	}

	&.right {
		transform: rotate(-45deg);
	}

	&.down {
		transform: rotate(45deg);
	}

	&.left {
		transform: rotate(135deg);
	}


}


.close {
	--width:1em;
	--volume:2px;
	--color:#333;
	position: relative;
	display: inline-flex;
	width: var(--width);
	height: var(--width);
	cursor: pointer;
	overflow: hidden;

	&:before,
	&:after {
		position: absolute;
		top: 0;
		left:50%;
		content: '';
		height: var(--width);
		width: 0;
		border: solid var(--color);
		border-width: 0px var(--volume) 0 0;
		background-color: var(--color);

	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform:rotate(-45deg);
	}
}

[data-count]
{
	--backgound-color:var(--color-pink);
	position: relative;
	&::after{
		position: absolute;
		content: attr(data-count);
		width: 14px;
		height: 14px;
		right: -2px;
		top: -2px;
		background-color: var(--backgound-color);
		font-size: 8px;
		font-weight: 800;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 14px;
		color: #fff;
		border-radius: 50%;
		text-align: center;
	}
}

[data-count='0']{
	--backgound-color:var(--color-smoke);
	&::after{

	}
}
[data-text]{
	&::before{
		content: attr(data-text);
	}
}

::placeholder,
.placeholder{
	color: #818181;
}

.scrollbal{
	--scrollbar-width:5px;

	--scrollbar-track-width:5px;
	--scrollbar-track-border-radius:10px;
	--scrollbar-track-color:#ffffff;

	--scrollbar-scrollbar-thumb-color:#c1c1c1;
	--scrollbar-scrollbar-thumb-border-radius:10px;

	&::-webkit-scrollbar {
		width:  var(--scrollbar-width);
	}

	&::-webkit-scrollbar-track {
		background: var(--scrollbar-track-color);
		border-radius: var(--scrollbar-track-border-radius);
		width: var(--scrollbar-track-width);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: var(--scrollbar-scrollbar-thumb-border-radius);
		background: var(--scrollbar-scrollbar-thumb-color);
	}

}


.props-list{
	list-style-type: none;
	padding: 0;
	margin: 0;

	&.--grid-2{
		column-count: 2;
		column-gap: 100px;
	}
	&__item{
		align-items: baseline;
		-moz-column-break-inside: avoid;
		break-inside: avoid;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
		page-break-inside: avoid;
		font-size: 15px;
		color: #000000;


	}
	&__title{
		align-items: baseline;
		display: flex;
		flex: 1;
		flex-grow: 0;
		white-space: nowrap;

		font-style: normal;
		font-weight: 300;
		color: #646C73;
	}
	&__sep{
		border-bottom: 0px dotted #4b545b;
		content: "";
		flex-grow: 1;
		margin: 0 6px 0 2px;
		opacity: .25;
	}
	&__value{
		text-align: right;

	}
}




.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.mb1{
	margin-bottom: 1rem;
}



.table{
	display: table;
	width: 100%;
	.table-th{
		display: table-row;
		background: whitesmoke;
		font-weight: bold;
		background: #ddd!important;
		.table-cell{
			font-weight: bold;
			padding: 1rem 20px;
		}

	}
	&-row{
		display: table-row;

	}
	&-cell{
		display: table-cell;
		box-sizing: border-box;
		padding: 1rem;
		a{
			text-decoration: underline;
		}
	}
}



.pagenation{
	display: flex;
	justify-content: center;
	align-items: center;
	gap:0 10px;
	&__link{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #e3e3e3;
		border-radius: 9px;
		&.-active{
			color: #fff;
			background: var(--color-btn-blue-light);
		}
	}
}