.jettons{
	display: flex;
	gap: 5px;
}
.jetton{
	display: inline-flex;

	input{
		display: none;
	}

	span{
		padding: 8px 20px;
		background: #FFFFFF;
		border: 1px solid #ECF1F5;
		box-sizing: border-box;
		border-radius: 12px;
		cursor: pointer;
		color: #4B668F;
		font-weight: 600;
		transition: var(--animation);
		line-height: 1em;
		font-size: 12px;
	}
	input:checked + span{
		color: var(--color-darken);
		box-shadow: inset 0 0 0 1px var(--color-darken);
		border-color: var(--color-darken);
	}
	&:hover{
		span{
			border-color: var(--color-input-stroke);
		}
	}

	&.light{
		&:hover{
			span{
				background: var(--color-smoke-2)
			}
		}
		span{
			border: 0;
			color: #000;
			padding: 6px 8px;
			border-radius: 8px;
		}
		input:checked + span{
			color: var(--color-main);
			background: #E2EAF1;
			box-shadow: none;
		}
	}
}