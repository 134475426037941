@import "./fonts";


.header{
	background: #2196f3;
	background: var(--color-main);
	width: 100%;
	position: relative;
	z-index: 1;
	.container{
		display: grid;
		grid-template-columns:  332px 1fr 230px;
		align-items: center;

		@include size(m){
			grid-template-columns: 50px 250px 1fr;
		}

		@include size(s){
			grid-template-columns: 80px 1fr 80px;
		}

		@include screen(400){
			grid-template-columns: 60px 1fr 60px;
		}
	}
	.logo{
		display: block;
		margin-top: -3px;
		.icon{
			--width: 160px;
			--height: 20px;
			display: block;
			@include size(m){
				margin: 0 auto;
			}
			@include screen(400){
				--width: 120px;
			}
		}
	}

	&__logo{
		display: flex;
		gap: 10px;
		padding: 10px 0;
		img{
			max-height: 70px;
			width: auto;
			image-rendering: -webkit-optimize-contrast;
			margin: 0 auto;
			@include size(m){
				max-height: 50px;
			}

			@include size(s){
				max-height: 38px;
			}
		}
	}

	&__nav{
		display: flex;
		gap: 0 20px;

		@include size(m){
			display: none;
		}

		&-item{
			color: #fff!important;
			font-size: 16px;


		}
	}

	&__aside{
		display: none;
		.icon{
			cursor: pointer;
			--fill:#fff;
		}

		@include size(m){
			display: inline-flex;
		}
	}

	&__profile{
		display: flex;
		justify-content: flex-end;
	}
}

.application{

}

.footer{
	width: 100%;

	.container{

	}

}

.profile{
	display: grid;
	position: relative;
	grid-template-columns: 1fr 40px 20px;
	align-items: center;
	padding: 1rem 5px;
	cursor: pointer;
	width: min-content;

	@include size(s){
		grid-template-columns: 40px 20px;
	}

	@include screen(400){
		grid-template-columns: 30px 20px;
	}


	&__title{
		color: #fff;
		white-space: nowrap;
		margin-right: 10px;
		&-name{
			font-size: 15px;
		}
		&-sub{
			font-size: 14px;
			color: #f1f1f1;
			font-weight: 300;
		}

		@include size(s){
			display: none;
		}
	}
	&__logo{
		img{
			width: 38px;
			height: 38px;
			border-radius: 50%;
			object-fit: cover;
			display: block;
			@include screen(400){
				width: 30px;
				height: 30px;
			}
		}

	}

	&__arrow{
		.icon{
			--fill: #fff;
			--ui-icon-width: 12px;
			margin-left: 8px;
		}
	}

	.profile__dropdown{
		display: none;
		position: absolute;
		top: 100%;
		right: 0px;
		z-index: 1;
		background: #fff;
		box-sizing: border-box;
		padding: 14px;
		box-shadow: 0px 0px 10px 2px #00000047;
		border-radius: 5px;
		&-item{
			display: block;
			width: 100%;
			text-align: left;
			padding: 5px;
			font-size: 15px;
		}
	}

	&:hover{
		background: #ffffff1f;
		.profile__dropdown{
			display: block;
		}
	}
}

.main{
	--aside-width:300px;
	width: 100%;

	@include screen(400){
		--aside-width:260px;
	}
	.container{
		display: flex;
		min-height: 600px;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.aside{
		width: var(--aside-width);
		box-sizing: border-box;
		flex-wrap: wrap;

		@include size(m){
			display: none;
		}
	}
	.body{
		width: calc(100% - var(--aside-width));
		box-sizing: border-box;
		padding-left: 2rem;

		@include size(m){
			width: 100%;
			padding-left: 0;
		}
	}
}

.aside-nav-visible__bg{
	display: none;
}
.aside-nav-visible{
	position: relative;

	@include size(m){
		.aside-nav-visible__bg{
			content: '';
			background: #0000003b;
			position: absolute;
			z-index: 100;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: block;

			.icon{
				position: fixed;
				right: 1rem;
				top: 1rem;
				--fill:#fff;
			}
		}
		.aside{
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			overflow: auto;
			z-index: 120;
			border-radius: 0;
			background: #fff;
		}
	}
}


.breadcrumbs{
	display: flex;
	margin-bottom: 5px;

	&__item{
		a{
			display: inline-flex;
			color: #000;
			font-size: 14px;
			border-bottom: 1px solid #ddd;
			font-weight: 400;
		}
		span{
			display: inline-flex;
			font-size: 14px;
			color: #7e7e7e;
			font-weight: 400;
		}

		&:after{
			content: '/';
			color: #ddd;
			margin: 0  4px;
			display: inline-flex;
		}
		&:last-child{
			&:after{
				display: none;
			}
		}

	}
}
.titles{
	display: grid;
	margin-bottom: 2rem;
	grid-template-columns: 1fr 100px;
	justify-content: center;
	align-items: center;
	h1{
		font-weight: 700;
		font-size: 24px;
		line-height: 40px;
		color: #343434;
		line-height: 1em;
		@include size(s){
			font-size: 22px;
		}

		@include screen(400){
			font-size: 18px;
		}
	}
	.titles_btn{
		text-align: right;
		.btn{
			padding: 5px 10px;
			height: auto;
		}
	}
}

.nav-list{
	margin-bottom: 40px;
	width: 100%;
	padding: 1rem 0;
	background: white;
	border-radius: 10px;
	box-sizing: border-box;
	margin-bottom: 1rem;
	&__item{

		a{
			display: flex;
			align-items: center;
			font-size: 15px;
			color: #1e1e1e;
			padding: 10px 2rem;
			margin: 0;
			.icon{
				margin-right: 10px;
				--fill: #a7a7a7;
			}
			&.-active{
				color: #000;
				background-color: #00000008;
				font-weight: 600;
				.icon{
					--fill: #000
				}
			}
			&:hover{
				color: #000;
				.icon{
					--fill: #000
				}
			}


		}

		@include size(s){
			margin-bottom: 14px;
			a{
				font-size: 17px;
			}
		}
	}

	&__bottom{
		margin-top: auto;
		margin-bottom: 0;
		a {
			color: #2a6f84;
			.icon {
				margin-right: 10px;
				--fill: #2a6f84;
			}

		}
	}
}

.footer-logos{
	margin-top: 1rem;
	display: block;
	width: 100%;
	padding: 1rem;
	background: white;
	border-radius: 10px;
	box-sizing: border-box;
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
	img{
		display: inline-flex;
		width: auto;
		max-height: 60px;
	}
}

.search-form, .filter-form{
	display: grid;
	grid-template-columns: 1fr 50px;
	*{
		border: none;
		box-sizing: border-box;
		background-color: transparent;
		line-height: 1em;
	}
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 5px;


	select,
	input{
		box-sizing: border-box;
		font-size: 15px;
		padding: 10px;
		border-radius: 0;
		&:focus{
			outline: none;
		}
	}

	&__btn{
		display: flex;
		justify-content: center;
		align-items: center;
		.icon{
			--ui-icon-width:18px;
		}
	}
}
.filter-form{
	grid-template-columns: 1fr 1fr 1fr 50px;
	.search-form__select,
	.search-form__input{
		border-right: 1px solid #ddd;
	}
	@include size(m){
		grid-template-columns: 1fr 1fr;
	}
	@include size(s){
		grid-template-columns: 1fr ;
	}
}

.form-resume{
	border: 1px solid #ededed;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 10px;
	background: #fff;

	&__compact{
		.form-resume__section{
			grid-template-columns: 300px 1fr;
			@include size(m){
				grid-template-columns: 1fr ;

				&.form-resume__section-header{
					display: none;
				}
			}
		}
	}
	&__section{
		display: grid;
		grid-template-columns: 1fr 400px;
		justify-content: center;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #f1f1f1;



		@include size(m){
			grid-template-columns: 1fr ;

			&.form-resume__section-header{
				display: none;
			}
		}

		&:last-child{
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}
	}
	textarea{
		width: 100%;
		box-sizing: border-box;
		padding: 1rem;
		resize: vertical;
		border:1px solid var(--color-smoke);
		border-radius: 5px;

		@include size(s){
			min-height: 120px;
		}
	}


	.drop, .drop .drop-btn,
	select{
		@include size(s){
			width: 100%;
		}
	}

	&__question{
		@include size(m){
			font-weight: bold;
			margin-bottom: 1rem;
		}
	}

	&__answer{
		label.radio,
		label.checkbox{
			width: 100%;
			margin-bottom: 10px;
		}
	}
	.form-resume__footer{
		display: flex;
		flex-wrap: wrap;

		.form-resume__footer-right{
			margin-left: auto;
		}
		.btn{
			@include size(s){
				width: 100%;
			}
		}

		@include size(s){
			.form-resume__footer-left, .form-resume__footer-right{
				width: 100%;
			}
			.form-resume__footer-left{
				margin-bottom: 1rem;
			}
		}

	}
}
.select-multiple-dropdown .drop-btn,
select{
	border: 1px solid var(--color-smoke);
	background: white;
	box-sizing: border-box;
	padding: 8px;
	appearance: none;
	line-height: 1em;
	border-radius: 6px;
	width: auto;
	display: inline-flex;
	min-width: 130px;
	box-shadow: none;
	&:focus{
		outline: 1px solid var(--color-main);
	}
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 285 167' id='down'%3E%3Cpath d='M282.082 17.5108L267.808 3.23783C265.906 1.33183 263.715 0.381836 261.238 0.381836C258.767 0.381836 256.577 1.33183 254.675 3.23783L142.466 115.441L30.262 3.24084C28.359 1.33484 26.169 0.384834 23.695 0.384834C21.22 0.384834 19.03 1.33484 17.128 3.24084L2.856 17.5148C0.95 19.4168 0 21.6068 0 24.0818C0 26.5548 0.953 28.7448 2.856 30.6468L135.899 163.693C137.801 165.596 139.992 166.547 142.466 166.547C144.94 166.547 147.127 165.596 149.028 163.693L282.082 30.6468C283.984 28.7438 284.929 26.5538 284.929 24.0818C284.929 21.6068 283.984 19.4168 282.082 17.5108Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
	background-position:  calc(100% - 10px) center;
	background-repeat: no-repeat;
	background-size: 10px;
	padding-right: 30px;

	@include size(s){
		height: 50px;
		padding: 1rem;
		align-items: center;
	}
}
.select-multiple-dropdown{
	&.--open{
		.drop-btn{
			border: 1px solid var(--color-main);
		}
	}
}

.notice{
	--backgound-color: #fafdff;
	--border-color: var(--color-input-stroke);
	--title-color: #000;
	--text-color: #324833;
	display: block;
	width: auto;
	box-sizing: border-box;
	padding: 20px;
	border: 1px solid var(--border-color);
	margin-bottom: 1rem;
	border-radius: 10px;
	font-size: 14px;
	background: var(--backgound-color);
	&-title{
		font-weight: 600;
		display: block;
		margin-bottom: 10px;
		font-size: 16px;
		color: var(--title-color);
	}
	&-text{
		color: var(--text-color);
	}

	&.-error{
		--backgound-color: #ffdedc;
		--border-color: #ff746a;
		--title-color: #f44336;
	}

	&.-ok{
		--backgound-color: #ebffd3;
		--border-color: #baf575;
		--title-color: #4caf50;
	}

	a{
		text-decoration: underline;
		color: var(--color-main);
	}
}
.application{
	min-height: 100%;
	position: relative;
//	padding-bottom: var(--footer-height, 70px);
	background: #ececec;
}
.login-page{
	display: flex;
	width: 100%;
	height: 100vh;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
	justify-content: center;

	@include size(m){
		height: auto;
		box-sizing: border-box;
		padding: 2rem;
		.form{
			width: 100%;
			max-width: 100%;
		}


	}

	@include size(s){
		.login-page__container{
			width: 100%;
		}
	}

	.login-page__logo{
		text-align: center;
		margin-bottom: 2rem;
		display: none;
		img{
			max-height: 60px;
			margin: 0 auto;
			display: block;
			margin-bottom: 1rem;
		}
	}
}

.form{
	min-width: 260px;


	.form-name{
		@extend .h3;
	}

	.form-section{
		display: block;
		margin-bottom: 1rem;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.form-title{
		font-size: 15px;
		display: block;
		margin-bottom: 5px;
	}
	.form-value{
		.drop{
			width: 100%;
			.drop-btn{
				padding: 10px;
				line-height: 1em;
				border-radius: 6px;
				width: 100%;
				font-size: 15px;

				@include size(s){
					font-size: 16px;
					height: 50px;
				}
			}
		}

		.input{
			border: 1px solid var(--color-smoke);
			background: white;
			box-sizing: border-box;
			padding: 10px;
			appearance: none;
			line-height: calc(1em + 1px);
			border-radius: 6px;
			width: 100%;
			font-size: 15px;

			&:focus{
				outline: 1px solid var(--color-main);
			}

			@include size(s){
				font-size: 16px;
				height: 50px;
			}
		}
	}
	.form-submit{
		display: block;
		width: 100%;
	}
	.form-link{
		font-size: 14px;
		text-align: center;
		width: 100%;
		display: block;
		text-decoration: underline;
	}

	&.form-xl{
		max-width: 600px;
		box-sizing: border-box;
		border: 1px solid var(--color-smoke);
		padding: 2rem;
		border-radius: 8px;
		background: #f8fdff;

		@include size(s){
			padding: 1rem;
		}
	}
}


.filter{
	--gap: 10px;
	--section-count: 4;
	--section-width:calc(100% / var(--section-count));
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(var(--gap) - calc(var(--gap) * 2));
	margin-right:calc(var(--gap) - calc(var(--gap) * 2));
	align-items: center;

	box-sizing: border-box;



	.bordered{
		border: 1px solid var(--color-smoke);
		padding: 8px;
		border-radius: 8px;
		background: var(--color-smoke-2);
	}

	@include size(m){
		--section-count: 3;
	}

	@include size(s){
		--section-count: 2;
	}

	@include screen(400){
		--section-count: 1;
		margin: 0;
	}

	&__section{
		box-sizing: border-box;
		width: calc(100% / var(--section-count));
		padding: var(--gap);

		@include screen(400){
			padding: 0;
			margin-bottom: 1rem;
		}

		&-title{
			font-size: 14px;
			display: block;
			margin-bottom: 5px;
			color: #707070;
			font-weight: 300;
		}

		&.filter__submit{
			margin-left: auto;
			align-self: flex-end;
		}
	}

	.drop{
		width: 100%;
		.drop-btn{
			padding: 10px;
			line-height: 1em;
			border-radius: 6px;
			width: 100%;
			font-size: 15px;
			height:40px;
			align-items: center;

		}
	}

	.btn{
		--ui-color: var(--color-btn-blue);
		--border-color: var(--ui-color);
		--background: var(--ui-color);
		--color: #fff;
		span{
			color: #000;
			font-size: 15px;
		}
	}

	.input, select{
		border: 1px solid var(--color-smoke);
		background: white;
		box-sizing: border-box;
		padding: 10px;
		height:40px;
		appearance: none;
		line-height: calc(1em + 1px);
		border-radius: 6px;
		width: 100%;
		font-size: 15px;

		&:focus{
			outline: 1px solid var(--color-main);
		}
	}
}

.info-table{
	background: #fff;
	/* border: 1px solid #000; */
	border-radius: 8px;
	overflow: hidden;
	box-sizing: border-box;
	&.table {
		border-collapse: collapse;

	}
	.table-cell{
		padding: 10px 20px;
		vertical-align: middle;
	}
	.table-row{
		&:nth-child(odd){
			background-color: #fbfbfb;
		}

		&:hover{
			background: whitesmoke;
		}
	}
	@include size(m){
		display: block;
		.table-th{
			display: none!important;
		}
		.table-row{
			display: block;
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			border-radius: 8px;

			&:nth-child(even){
				background-color: whitesmoke;
			}

		}
		.table-cell{
			display: grid;
			width: 100%;
			grid-template-columns: 120px 1fr;
			padding: 0;
			margin-bottom: 15px;
			&:before{
				content: attr(data-title);
				font-weight: 400;
				font-size: 14px;
				color: #7e7e7e;
			}
			&:first-child{
				grid-template-columns:1fr;
				&:before{
					display: none;
				}
				color: #000;
				font-weight: 600;
				font-size: 18px;
				a{
					text-decoration: none;
				}
			}
			&:last-child{
				grid-template-columns:1fr;
				margin: 0;
			}
		}

	}
}
.table-buttons{
	text-align: right;
	.btn.-compacter.-light,
	a.btn.-compacter.-light,
	{
		--margin-inner: 8px;
		font-size: 14px;
		padding: 9px 8px;
		height: auto;
		background-color: white;
	}
}

.graphic-laue{
	background: #fff;
	box-sizing: border-box;
	border-radius: 8px;
	margin-bottom: 1rem;
	padding: 1rem 0;
}

.logo-page{
	max-width: 100px;
	img{
		display: block;
	}
}

.grid-box{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: 2rem;

	&_item{
		text-align: center;
	}
	.item-img{
		max-width: 200px;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	.item_title{
		font-size: 16px;
		font-weight: bold;
	}
}

.contact {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 20px;
	@include size(m){
		grid-template-columns: 1fr;
	}
}
.contact_item {
	min-height: 400px;
	@include size(m){
		min-height: 300px;
	}
}