@import "~normalize.css";
@import	"./config.scss";
@import	"./core/main.scss";
@import	"./ui/main.scss";
@import	"./web/main.scss";

@import	"./app.scss";







