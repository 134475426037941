$colors: (
    main:#2a6f84,
    darken:#003380,
    pink:#D1258C,
    gray:#9BA1A8,
    smoke:#B6C9D8,
    smoke-2:#EDF1F4,
    black:#000,
    font:#000,
    gradient-green:linear-gradient(92.91deg, #43C2BB 3.98%, #43C267 21.57%, #004680 99.87%),
    gradient-blue:linear-gradient(91.35deg, #393896 4.38%, #258FCA 29.37%, #393896 84.57%, #6C3896 111.87%),
    gradient-neon: linear-gradient(29.82deg, #393896 37.29%, #A0477D 99.06%, #D12578 111.42%),
);


$ui-colors:(
    input-stroke:#B6C9D8,
    input-stroke-focus: var(--color-main),
    btn-blue:#2a6f84,
    btn-blue-light:#23697e,
    btn-pink:#D12577,
    btn-pink-light:#EF2D89,
    ui-green-gradient:linear-gradient(92.91deg, #43C2BB 3.98%, #43C267 21.57%, #004680 99.87%),
);

$font: (
    family: "Museo Sans Cyrl",
    fontRatio:15,
    size:15px,
    line-height:1.1em,
);

$params: (
    text-shadow:  0 2px 2px rgba(0,0,0,.5),
    container-min-width:320px,
    container-max-width:1400px,
    container-margin:0px auto,
    container-padding:0 1rem,
    border-radius: 5px,
    animation:80ms,
    ui-icon-width:22px,
);

$screens: (
    l:1312px,
    m:1024px,
    s:640px,
    xs:420px,
);

:root {
    @each $name, $value in $font {
        --font-#{$name}:  #{$value};
    }
    @each $name, $c in $colors {
      --color-#{$name}: #{$c};
    }
    @each $name, $c in $ui-colors {
        --color-#{$name}: #{$c};
    }


    @each $name, $value in $params {
        --#{$name}:  #{$value};
    }
}