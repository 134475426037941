html,body{
    height: 100%;
    width: 100%;
}

body{
    line-height: var(--font-line-height);
    font-family: var(--font-family);
    font-size: var(--color-size);
    font-weight: normal;
    color:var(--color-font);
}

.container {
    max-width: var(--container-max-width);
    min-width: var(--container-min-width);
    margin: var(--container-margin);
    padding: var(--container-padding);
    box-sizing: border-box;
}

p{
    line-height: 1.5em;
    margin-top: 0;
	margin-bottom:1em;
	word-wrap: break-word;
}
b{
    font-weight: bold;
}

a,
a:visited{
    text-decoration: none;
    color:var(--color-links)
}

button, input, select, textarea{
    font-family: var(--font-family);
	font-weight: normal;
    color:var(--color-font);
}

button, select{
    cursor: pointer;
}

img{
    max-width: 100%;
    display: inline-flex;
}
