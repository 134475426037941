

$heading:1,2,3,4,5,6;

@each $headTag in $heading {
    h#{$headTag}, .h#{$headTag}{
        display: block;
        line-height: 1.2em;
        margin: 0;
        padding: 0;
        font-weight: 600;
    }
}


$gap:1,2,3,4,5,6;

@each $g in $gap {
	.mt#{$g}{ margin-top: #{$g}rem; }
	.mb#{$g}{ margin-bottom: #{$g}rem; }
	.pt#{$g}{padding-top:  #{$g}rem; }
	.pb#{$g}{padding-bottom: #{$g}rem; }
	.gap#{$g}{gap: #{$g}rem; }
}




$tags: (
    'body': (
		"font-size":"var(--font-size)",
	),
    'h1': (
		"font-size":"24px",
		"font-weight":700,
	),
    'h2': (
		"font-size":"20px",
		"font-weight":600,
		"margin-bottom":'15px',
	),
    'h3': (
		"font-size":"18px",
		"font-weight":700,
	),
    'h4': (
		"font-size":"18px",
	),
    'h5': (
		"font-size":"16px",
	),
);

@each $tagName, $tagStyles in $tags {
	#{$tagName}, .#{$tagName}{
		@each $tagAttr, $value in $tagStyles {
			#{$tagAttr}:#{$value};
		}
	}
}


@include size(m){
	$tags: (
			'h1': ("font-size":"25px",),
			'h2': ("font-size":"20px",),
			'h3': ("font-size":"18px",),
			'h4': ("font-size":"16px",),
			'h5': ("font-size":"14px",),
	);

	@each $tagName, $tagStyles in $tags {
		#{$tagName}, .#{$tagName}{
			@each $tagAttr, $value in $tagStyles {
				#{$tagAttr}:#{$value};
			}
		}
	}
}


#app{
	table{
		border: 2px solid var(--color-main);
		border-radius: 5px;
		border-collapse: collapse;
		td{
			padding: 1em;
			border: 1px solid var(--color-main);
		}
	}
	
	ol,
	ul{
		line-height: 1.5em;
		li{

		}
	}

	p{

	}
	b{

	}

	.blackquote{
		display: block;
		width: 100%;
		padding: 1em;
		border: 2px solid var(--color-main);
		border-radius: 5px;
		margin-bottom: 1em;

		line-height: 1.5em;
	}
}