a.btn,
.btn{
	--ui-color:var(--color-btn-blue);

	--border-color:var(--ui-color);
	--background:var(--ui-color);
	--color:#fff;
	--font-size:1em;
	--padding-top-bottom:10px;
	--padding-left-right:20px;
	--margin-inner:10px;
	--icon-size:calc(1em * 1.2);
	--transition:100ms;

	@extend .input;

	position: relative;
	transition: var(--animation);

	color: var(--color);
	font-size: 14px;
	font-weight: 600;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	align-content: space-between;

	outline: none;

	border: 1px solid var(--border-color);
	border-radius: 8px;
	background: var(--background);
	text-decoration: none;
	cursor: pointer;

	&.-height-fix{
		height: auto;
	}

	&:hover{
		--ui-color:var(--color-btn-blue-light)
	}


	&:focus{
	//	outline-color:  var(--border-color);
	}

	&:not(-light):not(.-stop-animation):not(.--is-loading):not(.-static):hover{

	}
	&.--pink{
		--ui-color:var(--color-btn-pink);
		&:hover{
			--ui-color:var(--color-btn-pink-light)
		}
	}

	.icon{
		width: var(--icon-size);
		height:var(--icon-size);
		--fill:var(--color);
		fill:var(--color);
		margin: 0 calc(var(--margin-inner) / 2.5);
		box-sizing: border-box;
		transition: var(--transition);

	}

	span{
		color: var(--color);
		font-size: var(--font-size);
		margin: 0 calc(var(--margin-inner) / 2.5);
		.light{
			font-weight: 400;
		}
	}







	@each $name, $c in $colors {
		&.--#{$name}{
			--ui-color: #{$c};
			&.--is-loading{
				--color:#fff;
			}

		}
	}

	&.--white{
		--ui-color: #fff;
		--color:#000;
	}

	/**
		размерная таблица
	 */
	&.-compact{
		--margin-inner: 8px;
		font-size: 14px;
		padding: 10px 16px;
	}

	&.-compacter{
		--margin-inner: 8px;
		font-size: 14px;
		padding: 8px 14px;

		@include size(s){
			font-size: 15px;
			padding: 12px 15px;
		}
	}

	&.-border-only{
		--ui-color: #3F68A7!important;
		--background: transparent;
		--color: #fff;
	}




	/**
		таблица состояний
	 */

	&.-rouded{
		border-radius: 12px;
	}

	&.-link{
		padding: 0;
		margin: 0;
		--margin-inner: 0;
		--color:var(--ui-color);
		--border-color:transparent!important;
		--background: transparent!important;
	}

	&.-light{
		--color: #2a6f84;
		--border-color: #2a6f844a;
		--background: #fff!important;
		&:hover{
			--border-color:#2a6f84;
		}
	}

	&.-icon{
		--color: #000;
		--border-color: transparent!important;
		--background: transparent!important;
		--padding:0px;
		--icon-size: 32px;
		padding: var(--padding);
		width: var(--icon-size);
		height:var(--icon-size);
		border-radius: 0;

		svg.icon{
			width: 100%;
			height: 100%;
		}
	}

	&.-file{
		cursor: pointer;
		input[type=file],
		input[type=image]
		{
			display: none;
		}
	}

	&.--is-loading{
		--fill:var(--color);
		position: relative;

		cursor: default;
		* {
			opacity: 0;
		}
		span{
			visibility: hidden;
		}
		&:before{
			opacity: 1;
		}
	}


}

@keyframes hover {
	0% {
		transform: translateY(0%);
		opacity: 0;
	}
	50% {
		transform: translateY(50%);
		opacity: 1;
	}

	100% {
		transform: translateY(100%);
		opacity: 0;
	}
}



.icon-btn{
	--ui-box-size:30px;
	--ui-icon-width: 16px;
	--ui-border-color: transparent;
	--ui-filled-color: var(--color-pink);
	background: #fff;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: var(--ui-box-size);
	height: var(--ui-box-size);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	z-index: 2;
	border: 1px solid var(--ui-border-color);

	&.--filled{
		background: var(--ui-filled-color);
		--ui-border-color: var(--ui-filled-color)!important;
		.icon{
			--fill:var(--ui-filled-color);
			--stroke:#fff;
		}
	}
}
