.snap{
	--ui-icon-width: 15px;
	--between-space: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	background: #FFFFFF;
	padding: 20px calc(20px - var(--between-space));
	transition: var(--animation);
	border-radius: 30px;
	height: 58px;
	min-width: 58px;
	text-align: center;
	box-shadow: 0px 8px 20px rgba(6, 6, 21, 0.15);
	&:hover{
		box-shadow: 0px 8px 20px rgba(6, 6, 21, 0.3);
	}

	> * {
		margin: 0 var(--between-space);

	}

}