.drop{
    position: relative;

    width: auto;
    display: inline-flex;
    
    &.w100{
        width: 100%;
        .drop-container{
            width: 100%;
        }
        .drop-btn{
            width: 100%;
        }
    }


    .drop-icon{
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        transform-origin: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .drop-btn{
        cursor: pointer;
        margin-bottom: 0;
    }

    .drop-container{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: translateY(5px);
        top: 100%;
        left: 0;
        padding-top: 0;
    //    transition: 200ms;
		box-sizing: border-box;
        z-index: 1;

        @include size(s){
            right: 0;
        }

        &::before {

            position: absolute;
            left: 2em;
            top: 0;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 10px solid #fff;
        
        }
    }
    .drop-content{
        min-width: 100%;
        background: #fff;
        padding: 15px;
        box-shadow: 0px 0px 10px #0003;
        border-radius: 10px;
        box-sizing: border-box;
        box-sizing: border-box;
        max-height: 300px;
        overflow: auto;


        @include size(s){
            width: 100%;
        }

        @extend .scrollbal;

        .drop-content__radio,
        .drop-content__checkbox{
            display: block;
            margin-bottom: 5px;
            white-space: nowrap;
            &:last-child{
                margin-bottom: 0;
            }

            @include size(s){
                width: 100%;
            }
        }
    }
    .drop-submit{
        @extend .btn;

        position: fixed;
        top: calc(100% + 50px);
        right: 10px;
        left: 10px;
        display: none;
      
        --padding: 12px;
        --font-size: 14px;
   
    }
    .drop-item{
        display: flex;
        position: relative;
        cursor: pointer;
        padding: 12px 15px 12px;
        color: #000;
        text-decoration: none;
        line-height: 1em;
        &:hover{
            background: var(--color-smoke);
        }

        &.checkbox,
        &.radio{
            input{
                top: 50%;
                transform: translateY(-50%);
            }

        }
    }

    
    &.--open{
        
        .drop-btn{
    
        }

        .drop-container{
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
        .drop-icon{
            transform: translateY(-50%)  rotate(180deg);
        }
    }

    &.-full{
        .drop-submit{
            display: flex;
          
        }

        .drop-container{
            padding-bottom: 60px;
        }

    }

    &.-full{
        .drop-submit{
            display: flex;
          
        }

        .drop-container{
            padding-bottom: 60px;
        }

    }
}