.cards{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(310px, min-content));
	gap: 1em;
}
.cards-horizontal{
	display: block;
	.card{
		display: block;
		margin-bottom: 2rem;
	}

}
.card{
	max-width: 320px;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	letter-spacing: -0.01em;
	transition: var(--animation);
	width: 100%;
	color: #000000;

	&:hover{
		box-shadow: 0px 4px 30px rgba(15, 22, 41, 0.15);
	}


	&-header{
		display: block;
		position: relative;
		height: 200px;
		background: whitesmoke;
	}
	&-plate{
		position: absolute;
		left: 25px;
		top: 15px;
		background: #FFFFFF;
		border-radius: 8px;
		color: #4B668F;
		font-weight: 600;
		font-size: 11px;
		padding: 6px 10px;
		line-height: 1em;
		z-index: 2;
	}

	&-wishlist{
		--ui-icon-width: 16px;
		position: absolute;
		right: 25px;
		top: 15px;
		background: #fff;
		border: none;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		width: 26px;
		height: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		z-index: 2;

		&__active{
			--fill:red;
			--stroke:red;
		}
	}

	&-image{
		display: block;
		width: 100%;
		height: 100%;
		img{
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		span{
			display: none;
		}
	}

	&-slider{
		position: relative;
		width: 100%;
		height: 100%;

		&__pagenation{
			position: absolute;
			left: 25px;
			bottom: 10px;
			z-index: 1;
		}

		&__arrow{
			&.snap{
				--ui-icon-width: 11px;
				--between-space: 0px;
				padding: 0px;
				position: absolute;
				top: 50%;
				z-index: 2;
				cursor: pointer;
				transform: translateY(-50%);
				border-radius: 50%;
				height: 30px;
				min-width: 30px;
			}



			&-left{
				left: 10px;
			}
			&-right{
				right: 10px;
			}

		}
	}

	&-body{
		display: block;
		box-sizing: border-box;
		padding: 22px 25px;
	}
	&-price{
		font-weight: 600;
		font-size: 21px;
		color: #000000;
		margin-bottom: 5px;
	}

	&-price{

	}
	&-inline{
		display: flex;
		gap: 10px;
		font-size: 15px;
		color: #646C73;
		margin-bottom: 10px;
		font-weight: 300;
	}

	&-link{
		font-size: 15px;
		color:var(--color-main);
		font-weight: 500;
		border-bottom: 1px solid #DAE7F1;
	}

	&-address{
		font-size: 13px;
		color: #646C73;
	}

	&-list{
		margin-top: 20px;
		display: block;
		&__row{
			display: grid;
			grid-template-columns: 1fr 50px 100px;
			font-size: 13px;
			color: #646C73;

			&:not(:last-child){
				margin-bottom: 8px;
				border-bottom: 1px solid var(--color-smoke-2);
				padding-bottom: 6px;
			}

		}
		&__descr{
			text-align: right;
		}
		&__price{
			font-weight: 600;
			color: #000000;
			text-align: right;
		}
	}

	&-footer{
		padding: 0 23px 15px;

	}
	&-complete{
		font-size: 12px;
		color: #9BA1A8;
	}

	&.-short{
		.card-address{
			color: #000;
			font-weight: 600;
		}
	}

	&.-horizontal{
		display: grid;
		grid-template-columns: 300px 1fr;
		max-width: 100%;
		.card-header{
			height: 100%;
			border-radius: 8px;
			overflow: hidden;
		}
		.card-body{
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			padding: 2rem 2rem 1rem;
			&__main{
				width: 75%;
				padding-right: 1rem;
				box-sizing: border-box;
			}
			&__buttons{
				width: 25%;
				box-sizing: border-box;
				.btn{
					display: inline-flex;
					margin-bottom: 10px;
					width: 100%;
				}
			}
			&__footer{
				display: flex;
				width: 100%;
				gap: 1rem;
				align-items: center;
				border-top: 1px solid var(--color-smoke-2);
				margin-top: auto;
				padding: 15px 0 0;
				span{
					font-style: normal;
					font-weight: normal;
					font-size: 11px;
					color: #9BA1A8;
					&:last-of-type{
						margin-left: auto;
					}
				}
			}
		}
		.card-title{
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 10px;
			display: block;
		}
		.card-inline {
			color: #000000;
			margin-bottom: 10px;
			font-weight: 300;
			font-size: 14px;
			display: block;
		}
		.card-address{
			font-size: 13px;
			color: #000000;
			position: relative;
			padding-left: 20px;
			margin-bottom: 20px;
			margin-top: 15px;
			display: block;
			&:before{
				content: '';
				background: url("/img/location.svg") no-repeat center;
				background-repeat: no-repeat;
				position: absolute;
				width: 14px;
				height: 14px;
				background-size: contain;
				left: 0;
				top: 1px;
			}
		}
		.card-btn{
			--border-color:var(--color-smoke-2);
			&__number{
				--border-color:var(--color-smoke-2);
				--background: var(--color-smoke-2)!important;
			}
		}
		.card-text{
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			color: #646C73;
			display: block;
		}
	}


}

.poster{
	&-main{
		height: 373px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		align-content: flex-end;
		position: relative;
		overflow: hidden;
		border-radius: 10px;
		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(0deg, #0000006b, transparent);
			padding-top: 80%;
			z-index: 2;
		}

	}
	&-body{
		position: relative;
		z-index: 2;
		display: flex;
		flex-wrap: wrap;
		padding: 25px 35px;
	}
	&-image{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 1;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	&-title{
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 120%;
		color: #fff;
		border-bottom: 1px solid #9EA0A2;
		margin-bottom: 5px;
	}

	&-address{
		width: 100%;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 120%;
		color: #E0E0E0;
		margin-bottom: 13px;
	}
	&-complete{
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		color: #9BA1A8;
	}
	&-footer{
		padding: 12px 23px;
		display: block;
		font-size: 14px;
	}
	&-text{
		display: block;
		width: 100%;
		&.-gray{
			color: #646C73;
		}
	}
}