
input[type=range] {

    width: 100%;
    -webkit-appearance: none;
    background: var(--range-backgdound);
    &:focus {
        outline: none;
    }

    &:disabled {
        opacity: 0.5;
    }

    &::-webkit-slider-runnable-track {
        height: var(--range-track-height);
        color: red;
        background: var(--range-track-backgdound);;
    }

    &::-webkit-slider-thumb {
        width: var(--range-size);
        -webkit-appearance: none;
        height: var(--range-size);
        cursor: pointer;
        background: #fff;
        position: relative;
        transform: translateY(-50%);
        border-radius: 50%;
        background: var(--range-pin-color);
        box-shadow: 0 1px 2px 0 var(--range-pin-color);
        //	box-shadow: inset 0px 0px 0px 5px #d946af, 1px 1px 2px 0px #0000002e;
    }

    &::-webkit-progress-value {
        background-color: orange;
    }

    &::-moz-range-progress {
        background-color: #43e5f7;
    }
    &::-moz-range-track {
        background-color: #9a905d;
    }
    &::-ms-fill-lower {
        background-color: #43e5f7;
    }
    &::-ms-fill-upper {
        background-color: #9a905d;
    }
}

.range{
    --range-color-main:var(--ui-color);
    --range-size:18px;
    --range-backgdound:transparent;
    --range-track-backgdound:transparent;
    --range-pin-color:var(--color-main);
    --range-progress-color:var(--color-main);
    --range-track-color:transparent;
    --range-track-height:2px;

    z-index: 1;
    position: relative;
    display: block;
    width: 100%;
    height: var(--range-track-height);

    input[type=range] {
        width: 100%;
        appearance: none;
        background: var(--range-track-color);
        height: var(--range-track-height);
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: 0.5;
        }

        &::-webkit-slider-runnable-track {
            height: var(--range-track-height);
            background: var(--range-track-color);
        }

        &::-webkit-slider-thumb {
            width: var(--range-size);
            -webkit-appearance: none;
            height: var(--range-size);
            cursor: pointer;
            background: #fff;
            position: relative;
            transform: translateY(-50%);
            border-radius: 50%;
            background: var(--range-pin-color);
            box-shadow: 0 1px 2px 0 var(--range-pin-color);
            z-index: 2;
            //	box-shadow: inset 0px 0px 0px 5px #d946af, 1px 1px 2px 0px #0000002e;
        }

        &::-webkit-progress-value {
            background-color: orange;
        }

        &::-moz-range-progress {
            background-color: #43e5f7;
        }
        &::-moz-range-track {
            background-color: #9a905d;
        }
        &::-ms-fill-lower {
            background-color: #43e5f7;
        }
        &::-ms-fill-upper {
            background-color: #9a905d;
        }
    }


    &-progress{
        position: absolute;
        height:  var(--range-track-height);
        background: var(--range-progress-color);
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        max-width: 100%;
    }
}
