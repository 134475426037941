.input{
	--border-color:var(--color-input-stroke);
	--background-color:#fff;

    border: 1px solid var(--border-color);
    padding: 10px 20px;
    border-radius: 8px;
    box-sizing: border-box;
    background: var(--background-color);
    outline: none;
	font-size: 13px;
	color: #000;
	font-weight: 400;
	height: 44px;

	&::placeholder {
		color: #858585;
	}



	&:disabled{
		cursor: default;
		--font-color: #c9c9c9!important;
		--border-color: #e7e7e7!important;
		--background-color: #f7f7f7!important;
	}

	&.-validated:not([value=""]):not(:focus):invalid{
//		border-color: #fb1919;
	}

	&.-validated:not([value=""]):valid {
//		border-color: var(--color-green);
	}

	&:focus{
		--border-color:var(--color-input-stroke-focus)!important;
	}



}

input[type=date].input{
	&::-webkit-date-and-time-value{ text-align:left; }
}

input.input{
	-webkit-min-logical-width: calc(100% - 16px);
}
textarea.input{
    resize: vertical;
    min-height: 120px;
}

.input-container{
	display: inline-flex;
	flex-wrap: wrap;
	position: relative;
	.input-header{
		display: flex;
		width: 100%;
		line-height: 1em;
		align-items: center;
		margin-bottom: 5px;
		.input-tabs{
			margin-left: auto;
		}
	}
	.input-title{
		display: inline-flex;
		font-weight: 600;
		font-size: 13px;
	}
	.input-value{
		display:block;
		width: 100%;
		position: relative;
	}
	.input-value-grid{
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 5px;
		width: 100%;

	}
	.input-icon{
		--ui-icon-width: 12px;
		position: absolute;
		right: 10px;
		bottom: 8px;
		font-size: 13px;
		color: #004680;
		font-weight: 700;
	}
}