.checkbox, .radio{
	--icon-size: 20px;
	--color: var(--color-blue);
	display: inline-flex;
	cursor: pointer;
	line-height: 1em;
	position: relative;
	align-items: flex-start;
	align-content: center;
	appearance: none;
	width: auto;

	display: grid;
    grid-template-columns: var(--icon-size) 1fr;



	@extend .noselect;

	&:not(.disabled):hover{
		input:not(:checked){
			opacity: 0.3;
			background: var(--color-main);
			border: 1px solid var(--color-main);

		}
	}

    input{

		
		margin: 0px;
		outline: none;
		display: block;
		cursor: pointer;
		appearance: none;
		border-radius: 5px;
		width: var(--icon-size);
		height: var(--icon-size);
		border: 1px solid var(--color-smoke);
		background: rgb(255, 255, 255);
		position: relative;
		top: 0px;
		-webkit-appearance: none;
		@include size(s){
			border: 1px solid var(--color-smoke)!important;
			background: rgb(255, 255, 255)!important;
		}



        &:checked{
            border: 2px solid var(--color-main);

             &::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 12px;
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg  fill='%23004494' viewBox='0 -46 417.81333 417'  xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0'/%3E%3C/svg%3E");
			}
        }
		&:checked + span{
		//	font-weight: 500;
		}
    }
    span{
		--padding-left: 10px;
		padding-left: var(--padding-left);
	//	width: calc(100% - calc(var(--icon-size) + var(--padding-left)));
		box-sizing: border-box;
		position: relative;
		top: 2px;
		white-space: break-spaces;
    }



	&.disabled{
		--color:#bdbdbd;
		cursor: default;
		span{
			color: var(--color);
			*{
				color: var(--color)!important;
			}
		}
		input{
			background-color: var(--color-whitesmoke);
			cursor: default
		}
	}

}

.checkbox{
	@include size(s){
		--icon-size: 22px;
	}
}


.radio{
	input{
		border-radius: 50%;
		&::before{
			display: none;
		}
		&:checked{
			box-shadow: inset 0px 0px 0px 4px #fff, inset 0px 0px 0px 10px var(--color-main);
		}
	}
}