.inline-tabs{
	display: flex;
	border-bottom: 1px solid #E5EDF5;
	gap: 0 25px;
	margin-bottom: 30px;
	.inline-tab{
		border: none;
		border-bottom: 1px solid transparent;
		background: transparent;
		padding: 8px 0;
		margin: 0;
		position: relative;
		top: 1px;
		font-weight: 300;
		color: #646C73;
		font-size: 16px;
		line-height: 1em;

		&.-active{
			color: var(--color-main);
			border-color: var(--color-main);
			font-weight: 500;
		}
	}
}